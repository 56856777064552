import { Place, TUserPlaceType, UserPlace } from '@geovelo-frontends/commons';
import { Add } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActions,
  CardHeader,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from './button';
import { EditIcon, RemoveIcon } from './icons';
import PlaceDialog from './place-dialog';
import RemovePlaceDialog from './remove-place-dialog';

export function PlaceData({
  placeType,
  label,
  selectedPlace,
  removable,
}: {
  label: string;
  placeType: Extract<TUserPlaceType, 'home' | 'work'> | 'company';
  removable?: boolean;
  selectedPlace: UserPlace | Place | null | undefined;
}): JSX.Element {
  const [placeDialogOpen, openPlaceDialog] = useState(false);
  const [removeDialogOpen, openRemoveDialog] = useState(false);
  const { t } = useTranslation();

  if (selectedPlace === null) {
    return (
      <>
        <Box
          alignItems="center"
          bgcolor="#F6F8FC"
          borderRadius={4}
          display="flex"
          flexDirection="column"
          gap={2}
          padding={5}
        >
          <Typography align="center">
            {t('companies.pages.admin.user.address_form.empty_state')}
          </Typography>
          <Button
            color="primary"
            onClick={() => openPlaceDialog(true)}
            size="medium"
            startIcon={<Add />}
            variant="outlined"
          >
            {t('companies.pages.admin.user.address_form.actions.add')}
          </Button>
        </Box>
        <PlaceDialog
          currentPlace={null}
          onClose={() => openPlaceDialog(false)}
          open={placeDialogOpen}
          placeType={placeType}
        />
      </>
    );
  }

  return (
    <>
      <Card
        sx={{ alignItems: 'flex-start', borderRadius: 4, display: 'flex', flexDirection: 'row' }}
        variant="outlined"
      >
        <CardHeader
          subheader={
            selectedPlace ? selectedPlace.address : <Skeleton variant="text" width={200} />
          }
          subheaderTypographyProps={{ variant: 'caption' }}
          sx={{ flexGrow: 1 }}
          title={
            (placeType === 'work' &&
              selectedPlace &&
              'title' in selectedPlace &&
              selectedPlace.title) ||
            label
          }
          titleTypographyProps={{ variant: 'body2' }}
        />
        <CardActions sx={{ flexShrink: 0 }}>
          {selectedPlace ? (
            <>
              <Tooltip
                placement="top"
                title={t('companies.pages.admin.user.address_form.actions.update')}
              >
                <IconButton color="primary" onClick={() => openPlaceDialog(true)} size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              {removable && 'type' in selectedPlace && selectedPlace.type === 'work' && (
                <Tooltip placement="top" title={t('commons.actions.remove')}>
                  <IconButton color="error" onClick={() => openRemoveDialog(true)} size="small">
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ) : (
            <>
              <Skeleton height={30} variant="circular" width={30} />
              {removable && <Skeleton height={30} variant="circular" width={30} />}
            </>
          )}
        </CardActions>
      </Card>
      {selectedPlace && (
        <>
          <PlaceDialog
            currentPlace={selectedPlace}
            onClose={() => openPlaceDialog(false)}
            open={placeDialogOpen}
            placeType={placeType}
          />
          {'type' in selectedPlace && (
            <RemovePlaceDialog
              onClose={() => openRemoveDialog(false)}
              open={removeDialogOpen}
              place={selectedPlace}
            />
          )}
        </>
      )}
    </>
  );
}
